<template>
  <div>
    <b-overlay :show="show" rounded="sm" no-fade>
      <b-card-code title="Pembuatan SIP" v-if="this.sip_stts != 0">
        <!-- search input -->
        <b-row class="mt-2 mb-2" align="end">
          <b-col align="start">
            <!-- empty -->
          </b-col>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="showModalTambah()"
            >
              <span class="align-middle">Tambah SIP</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: false,
          }"
          @on-sort-change="onSortChange"
          line-numbers
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'created_date'">
              {{ props.row.created_date.substring(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'status_perizinan'">
              <span v-if="props.row.status_perizinan === 0">
                <b-badge variant="warning"> Belum Diverifikasi </b-badge>
              </span>

              <span v-if="props.row.status_perizinan === 1">
                <b-badge variant="success"> Sudah Diverifikasi </b-badge>
              </span>

              <span v-if="props.row.status_perizinan === 9">
                <b-badge variant="danger"> DItolak </b-badge>
              </span>
            </span>

            <span v-else-if="props.column.field === 'berkas_persyaratan'">
              <ul>
                <li v-for="item in props.row.berkas_persyaratan" :key="item">
                  {{ item }}
                </li>
              </ul>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <div class="demo-inline-spacing" v-if="props.row.status_perizinan != 9">
                <b-button
                  v-if="props.row.nama_berkas.length"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  v-b-tooltip.hover.v-success
                  title="Lihat Dokumen"
                  class="btn-icon"
                  @click="showModalBerkas(props)"
                >
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-button>

                <b-button
                  v-if="!props.row.nama_berkas.length"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  v-b-tooltip.hover.v-success
                  title="Lihat Dokumen"
                  class="btn-icon"
                  @click="showModalBerkasNull(props)"
                >
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  v-b-tooltip.hover.v-primary
                  title="Upload Berkas Persyaratan"
                  class="btn-icon"
                  @click="showModalUpload(props)"
                >
                  <feather-icon icon="UploadIcon" class="cursor-pointer" />
                </b-button>

                <b-button
                  v-if="props.row.status_perizinan === 0"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  v-b-tooltip.hover.v-warning
                  title="Ubah Data SIP"
                  class="btn-icon"
                  @click="showModalEdit(props)"
                >
                  <feather-icon icon="EditIcon" class="cursor-pointer" />
                </b-button>

                <b-button
                  v-if="props.row.status_perizinan != 0"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  v-b-tooltip.hover.v-warning
                  title="Ubah Data SIP"
                  class="btn-icon"
                  @click="showModalEditCannot(props)"
                >
                  <feather-icon icon="EditIcon" class="cursor-pointer" />
                </b-button>
              </div>

              <div class="demo-inline-spacing" v-if="props.row.status_perizinan != 9">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-tooltip.hover.v-primary
                  title="Download SIP"
                  class="btn-icon"
                  @click="downloadPDF(props)"
                  v-if="props.row.status_perizinan === 1"
                >
                  <feather-icon icon="DownloadIcon" class="cursor-pointer" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  v-b-tooltip.hover.v-primary
                  title="Download SIP"
                  class="btn-icon"
                  @click="downloadPDFNone(props)"
                  v-if="props.row.status_perizinan != 1"
                >
                  <feather-icon icon="DownloadIcon" class="cursor-pointer" />
                </b-button>
              </div>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->

          <!-- <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
             
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="paging.size"
                  :options="paging.perPageOptions"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap">
                  of {{ paging.total_elements }} entries
                </span>
              </div>

             
              <div>
                <b-pagination
                  :value="paging.page + 1"
                  :total-rows="paging.total_elements"
                  :per-page="paging.size"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template> -->
        </vue-good-table>

        <!-- modal detail Form SIP-->
        <b-modal
          id="modal-detail-membership-sip"
          ref="ref-payment-member-sip"
          title="Form SIP"
          :hide-footer="true"
          size="xl"
          no-close-on-backdrop
        >
          <b-form>
            <b-row>
              <!-- kiri -->
              <b-col>
                <b-form-group label="Nama Profesi" label-for="name">
                  <b-form-select
                    placeholder="Nama Profesi"
                    v-model="formSip.id_profesi"
                    size="lg"
                    @change="profesiCheck()"
                  >
                    <option :value="null" disabled>Pilih Profesi</option>
                    <option v-for="option in combo_profesi" :value="option.id">
                      {{ option.nama_profesi }}
                    </option>
                  </b-form-select>
                </b-form-group>

                <b-form-group
                  label="Nomor STR"
                  label-for="name"
                  v-show="this.showSTR === true"
                >
                  <b-form-input
                    id="name"
                    v-model="formSip.nomor_str"
                    placeholder="Masukkan Nomor STR"
                  />
                </b-form-group>

                <b-form-group
                  label="Penerbit STR"
                  label-for="name"
                  v-show="this.showSTR === true"
                >
                  <b-form-input
                    id="name"
                    v-model="formSip.penerbit_str"
                    placeholder="Masukkan Penerbit STR"
                  />
                </b-form-group>

                <b-form-group
                  label="Tanggal Terbit STR"
                  label-for="name"
                  v-show="this.showSTR === true"
                >
                  <flat-pickr
                    v-model="formSip.tanggal_terbit_str"
                    class="form-control"
                    placeholder="Pilih Tanggal Terbit"
                    :config="configs.allowInput"
                  />
                </b-form-group>

                <b-form-group label="Nomor Rekomendasi OP" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="formSip.no_rek_op"
                    placeholder="Masukkan Nomor Rekomendasi OP"
                  />
                </b-form-group>
              </b-col>

              <!-- kanan -->
              <b-col>
                <b-form-group
                  label="Praktik Mandiri / Praktek di Sarana Kesehatan"
                  label-for="name"
                >
                  <b-form-checkbox
                    v-model="formSip.is_praktik_mandiri"
                    value="true"
                    plain
                  >
                    Praktik Mandiri
                  </b-form-checkbox>

                  <b-form-checkbox
                    v-model="formSip.is_praktik_mandiri"
                    value="false"
                    plain
                  >
                    Praktek di Sarana Kesehatan
                  </b-form-checkbox>
                </b-form-group>

                <b-form-group label="Tempat Praktik" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="formSip.nama_tempat_praktik"
                    placeholder="Masukkan Tempat Praktik"
                  />
                </b-form-group>

                <b-form-group
                  label="Cara Perawatan"
                  label-for="name"
                  v-show="showCaraPerawatan === true"
                >
                  <b-form-input
                    id="name"
                    v-model="formSip.cara_perawatan"
                    placeholder="Masukkan Cara Perawatan"
                  />
                </b-form-group>

                <b-form-group label="Alamat Praktik" label-for="name">
                  <b-form-textarea
                    v-model="formSip.alamat_praktik"
                    id="textarea-default"
                    placeholder="Masukkan Alamat Praktik"
                    rows="4"
                  />
                </b-form-group>

                <b-form-group label="Koordinat Lokasi Praktik" label-for="name">
                  <b-form-input
                    id="name"
                    v-model="formSip.koordinat_lokasi_praktik"
                    placeholder="Masukkan Koordinat Lokasi Praktik"
                  />
                </b-form-group>

                <b-form-group label="Waktu Praktik" label-for="name">
                  <b-form-textarea
                    v-model="formSip.waktu_praktik"
                    id="textarea-default"
                    placeholder="Masukkan Waktu Praktik"
                    rows="4"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>

          <b-row>
            <b-col align="end"
              ><b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="submit"
              >
                Simpan
              </b-button>
            </b-col>
          </b-row>
        </b-modal>
        <!-- end modal detail form sip -->

        <!-- modal upload berkas syarat-->
        <b-modal
          id="modal-detail-payment-upload"
          ref="ref-payment-upload"
          title="Upload Berkas Persyaratan"
          :hide-footer="true"
          no-close-on-backdrop
        >
          <b-form>
            <b-form-group label="Nama Berkas Syarat" label-for="name">
              <b-form-select
                placeholder="Nama Berkas Syarat"
                v-model="formSyarat.nama_berkas"
                size="md"
              >
                <option :value="null" disabled>Pilih Berkas Syarat</option>
                <option v-for="option in combo_syarat" :value="option">
                  {{ option }}
                </option>
              </b-form-select>
            </b-form-group>

            <b-form-group label="Upload Berkas" label-for="name">
              <b-form-file
                ref="fileInput"
                v-model="file"
                placeholder="Pilih file anda"
                drop-placeholder="Drop disini ..."
                @change="handleImage()"
                accept="image/*,.pdf"
              />
            </b-form-group>
            <span style="font-size: 0.8em; display: block; margin-top: -10px;">
               Ukuran file setiap berkas maksimal 700KB
            </span>
          </b-form>

          <b-row>
            <b-col align="end">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                class="mr-2"
                @click="kembaliUpload()"
              >
                Kembali
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="submitBerkas()"
                v-if="this.isLoading === false"
              >
                <span>
                  Simpan
                </span>
              </b-button>

              <b-button variant="primary" disabled v-if="this.isLoading === true">
                <b-spinner small></b-spinner>
                <span class="sr-only">Loading...</span>
                Loading...
              </b-button>
            </b-col>
          </b-row>
        </b-modal>
        <!-- end modal upload -->

        <!-- modal get berkas-->
        <b-modal
          id="modal-detail-get-berkas"
          ref="ref-get-berkas"
          title="Tampilan Berkas"
          :hide-footer="true"
          no-close-on-backdrop
        >
          <b-form>
            <b-form-group label="Nama SIP" label-for="name">
              <b-form-input id="name" v-model="getBerkas.nama_sip" />
            </b-form-group>

            <b-form-group label="Tanggal dibuat" label-for="name">
              <b-form-input id="name" v-model="getBerkas.created_date" />
            </b-form-group>

            <b-form-group label="Nama Berkas" label-for="name">
              <b-form-input id="name" v-model="getBerkas.nama_berkas" />
            </b-form-group>

            <b-form-group label="Gambar Berkas" class="mb-2">
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewEl"
                    height="200"
                    width="400"
                    class="rounded mr-2 mb-1 mb-md-0"
                    :src="getBerkas.data_url"
                  />
                </b-media-aside>
              </b-media>
            </b-form-group>
          </b-form>

          <b-row>
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
            <b-col
              ><b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                @click="hideGetBerkas()"
              >
                Kembali
              </b-button>
            </b-col>
            <b-col></b-col>
            <b-col></b-col>
            <b-col></b-col>
          </b-row>
        </b-modal>
        <!-- end modal upload -->
      </b-card-code>

      <b-card-code v-else>
        <section id="knowledge-base-search">
          <b-card no-body class="knowledge-base-bg text-center">
            <b-card-body class="card-body">
              <h2 class="text-primary">Menunggu verifikasi data diri</h2>
              <b-card-text class="mb-2">
                <span>
                  Menu pembuatan SIP dapat dibuka jika data diri sudah
                  diverifikasi oleh admin.
                </span>
              </b-card-text>
            </b-card-body>
          </b-card>
        </section>
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  VBTooltip,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    flatPickr,
    BSpinner,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      //here
      file: null,

      combo_profesi: [],
      combo_syarat: [],

      sip_stts: 0,
      isLoading: false,

      hari_praktik_sementara: "",
      jam_praktik_sementara: "",
      jam_praktik_sementara_akhir: "",

      configs: {
        allowInput: {
          allowInput: true,
          dateFormat: "d-m-Y",
        },
      },
      showSTR: false,
      showCaraPerawatan: false,

      log: [],
      dir: false,
      pages: ["5", "10", "20", "30"],

      paging: {
        page: 0,
        total_pages: 0,
        size: 10,
        total_elements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      columns: [
        {
          label: "Nama",
          field: "nama_gelar",
          thClass: "text-center",
        },
        {
          label: "Nama SIP",
          field: "nama_sip",
          thClass: "text-center",
        },
        {
          label: "ID SIP",
          field: "id",
          thClass: "text-center",
        },
        {
          label: "Tanggal dibuat",
          field: "created_date",
          thClass: "text-center",
        },
        {
          label: "Nomor STR",
          field: "nomor_str",
          thClass: "text-center",
        },
        // {
        //   label: "Syarat Berkas",
        //   field: "berkas_persyaratan",
        //   thClass: "text-center",
        // },
        {
          label: "Status",
          field: "status_perizinan",
          thClass: "text-center",
        },
        {
          label: "Catatan",
          field: "catatan",
          thClass: "text-center",
        },
        {
          label: "Aksi",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center",
          width: "160px",
        },
      ],
      rows: [],
      searchTerm: "",

      combo_hari: [
        { value: "", text: "Hari", disabled: true },
        { value: "senin", text: "Senin" },
        { value: "selasa", text: "Selasa" },
        { value: "rabu", text: "Rabu" },
        { value: "kamis", text: "Kamis" },
        { value: "jumat", text: "Jum'at" },
        { value: "sabtu", text: "Sabtu" },
        { value: "minggu", text: "Minggu" },
      ],

      name: "",
      sort: "",

      formSip: {
        id_pendaftar: 0,
        id_profesi: null,
        nama_sip: "",
        nomor_str: "",
        catatan: "",

        is_praktik_mandiri: false,

        penerbit_str: "",
        tanggal_terbit_str: "",
        no_rek_op: "",
        nama_tempat_praktik: "",
        alamat_praktik: "",
        koordinat_lokasi_praktik: "",
        waktu_praktik: "",
        cara_perawatan: "",
      },

      formSyarat: {
        id_pendaftar: 0,
        id_sip: 0,
        id_profesi: 0,
        nama_berkas: null,
        data_url: "",
      },

      create_time: "",

      getBerkas: {},

      show: false,
      aksi: "",
      id_sip: "",

      center: {
        lat: 39.7837304,
        lng: -100.4458825,
      },
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,
    };
  },

  beforeMount() {
    this.sip_stts = localStorage.getItem("sip_stts");
    this.getData();
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },

  created() {
    //here
  },

  methods: {
    getComboProfesi() {
      return new Promise(() => {
        axios
          .get("pendaftar/master_profesi")
          .then((res) => {
            this.combo_profesi = res.data;
            this.show = false;
          })

          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    profesiCheck() {
      if (this.formSip.id_profesi === 24) {
        this.showSTR = false;
        this.showCaraPerawatan = true;
      } else if (this.formSip.id_profesi === 26) {
        this.showSTR = false;
        this.showCaraPerawatan = false;
      } else {
        this.showSTR = true;
        this.showCaraPerawatan = false;
      }

      return new Promise(() => {
        axios.get("pendaftar/master_profesi").then((response) => {
          this.combo_profesi = response.data;

          let indexProfesi = this.combo_profesi.findIndex(
            (x) => x.id === this.formSip.id_profesi
          );

          return new Promise(() => {
            axios.get("pendaftar/master_profesi").then((response) => {
              this.formSip.nama_sip = response.data[indexProfesi].nama_profesi;
            });
          });
        });
      });
    },

    getData() {
      this.show = true;

      let search = "";
      if (this.name) {
        search = "&name=" + this.name;
      } else {
        search;
      }
      let sort = "";
      if (this.sort) {
        sort = "&sort=" + this.sort;
      } else {
        sort = "&sort=createTime";
      }

      return new Promise(() => {
        axios
          .get(
            "pendaftar/list_sip?id_pendaftar=" +
              localStorage.getItem("id_pendaftar")
          )
          .then((res) => {
            this.rows = res.data;

            this.getComboProfesi();

            this.isLoading = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.isLoading = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.isLoading = false;
            }
            this.show = false;
            this.isLoading = false;
          });
      });
    },

    downloadPDF(props) {
      return new Promise(() => {
        axios
          .get("pendaftar/validasi_download_sip?id_sip=" + props.row.uuid)
          .then((res) => {
            // download atau tidak

            window.open(
              "https://sipolnakes.salatiga.go.id/api/v1/pendaftar/download_sip_pemohon?id_sip=" +
                props.row.uuid +
                "&is_pemohon=true"
            );
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    downloadPDFNone() {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: "Download SIP Hanya Bisa Jika Status Sudah Diverifikasi",
          icon: "XCircleIcon",
          variant: "danger",
        },
      });
    },

    showModalTambah() {
      this.$router.push("/pembuatan-sip/tambah");
      // this.aksi = "tambah";
      // this.formSip.id_pendaftar = localStorage.getItem("id_pendaftar");

      // this.$refs["ref-payment-member-sip"].show();
    },

    showModalEditCannot() {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: "Ubah Data SIP Hanya Bisa Jika Status Belum Diverifikasi",
          icon: "XCircleIcon",
          variant: "danger",
        },
      });
    },

    showModalEdit(props) {
      this.$router.push("/pembuatan-sip/" + props.row.id + "/ubah");

      // this.formSip.id_pendaftar = props.row.id_pendaftar;
      // this.formSip.id_profesi = props.row.id_profesi;
      // this.formSip.nama_sip = props.row.nama_sip;
      // this.formSip.nomor_str = props.row.nomor_str;
      // this.formSip.catatan = props.row.catatan;

      // this.formSip.penerbit_str = props.row.penerbit_str;
      // this.formSip.tanggal_terbit_str = props.row.tanggal_terbit_str;
      // this.formSip.no_rek_op = props.row.no_rek_op;
      // this.formSip.nama_tempat_praktik = props.row.nama_tempat_praktik;
      // this.formSip.alamat_praktik = props.row.alamat_praktik;
      // this.formSip.koordinat_lokasi_praktik =
      //   props.row.koordinat_lokasi_praktik;
      // this.formSip.waktu_praktik = props.row.waktu_praktik;
      // this.formSip.cara_perawatan = props.row.cara_perawatan;

      // this.id_sip = props.row.id;

      // this.$refs["ref-payment-member-sip"].show();
      // this.aksi = "edit";
    },

    showModalUpload(props) {
      this.show = true;

      this.formSyarat.id_pendaftar = props.row.id_pendaftar;
      this.formSyarat.id_sip = props.row.id;
      this.formSyarat.id_profesi = props.row.id_profesi;

      return new Promise(() => {
        axios
          .get(
            "pendaftar/berkas_persyaratan?id_profesi=" + props.row.id_profesi
          )
          .then((res) => {
            this.combo_syarat = res.data;

            this.$refs["ref-payment-upload"].show();

            this.show = false;
          });
      });
    },

    handleImage() {
      const file = this.$refs.fileInput.$el.querySelector('input[type=file]').files[0];
      if (file) {
        const fileSizeInMB = file.size / (1024*1024);
        if (fileSizeInMB > 1) {
          // alert("Ukuran file tidak boleh lebih dari 3MB.");

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Ukuran file tidak boleh lebih dari 700KB.",
              icon: "XCircleIcon",
              variant: "danger",
            },
          });
          
          // Mengatur ulang <b-form-file>
          if (this.$refs.fileInput && this.$refs.fileInput.$el) {
            this.$refs.fileInput.$el.querySelector('input[type=file]').value = '';
            this.file = '';
          }
          return;
        }
        
        const reader = new FileReader();
        reader.onloadend = () => {
          this.formSyarat.data_url = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },


    submit() {
      if (this.aksi === "tambah") {
        return new Promise(() => {
          axios
            .post("pendaftar/buat_sip", this.formSip)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Tambah Data Berhasil`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Tambah Data SIP Berhasil`,
                },
              });
              this.getData();
              this.$refs["ref-payment-member-sip"].hide();
              this.show = false;
            })
            .catch((error) => {
              if (error.response.status == 401) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
                this.$router.push("/login");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
              }
              this.show = false;
            });
        });
      } else {
        return new Promise(() => {
          axios
            .put("pendaftar/update_sip?id_sip=" + this.id_sip, this.formSip)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Ubah Data Berhasil`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Ubah Data SIP Berhasil`,
                },
              });
              this.getData();
              this.$refs["ref-payment-member-sip"].hide();
              this.show = false;
            })
            .catch((error) => {
              if (error.response.status == 401) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
                this.$router.push("/login");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
              }
              this.show = false;
            });
        });
      }
    },

    submitBerkas() {
      if(this.formSyarat.nama_berkas === null || this.formSyarat.nama_berkas === ""){
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Pilih Nama Berkas Terlebih Dahulu",
            icon: "XCircleIcon",
            variant: "danger",
          },
        });
      } else if(this.formSyarat.data_url === "" || this.formSyarat.data_url === null){
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "File Upload Berkas Masih Kosong",
            icon: "XCircleIcon",
            variant: "danger",
          },
        });
      } else {
        this.isLoading = true;
        return new Promise(() => {
          axios
            .post("pendaftar/upload_berkas", this.formSyarat)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Upload Berkas Berhasil`,
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text:
                    `Upload Berkas ` + this.formSyarat.nama_berkas + ` Berhasil`,
                },
              });
              this.getData();
              this.$refs["ref-payment-upload"].hide();
            })
            .catch((error) => {
              if (error.response.status == 401) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.isLoading = false;
                this.$router.push("/login");
              } else if (error.response.status === 413) {
                // Penanganan untuk status 413
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: 'Ukuran file setiap berkas maksimal 700KB',
                    icon: "XCircleIcon",
                    variant: "warning",
                  },
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.isLoading = false;
              }
              this.isLoading = false;
            });
        });
      }
    },

    kembaliUpload() {
      this.$refs["ref-payment-upload"].hide();
      this.isLoading = false;
    },

    openBerkas(props, item) {
      return new Promise(() => {
        axios
          .get(
            "pendaftar/get_berkas_sip?nama_berkas=" +
              item +
              "&id_sip=" +
              props.row.id
          )
          .then((res) => {
            this.getBerkas = res.data[0];
            this.getBerkas.created_date = res.data[0].created_date.substr(
              0,
              10
            );
            this.getBerkas.nama_sip = props.row.nama_sip;

            this.$refs["ref-get-berkas"].show();
          });
      });
    },

    hideGetBerkas() {
      this.$refs["ref-get-berkas"].hide();
    },

    deleteInfo(props) {
      this.$swal({
        title: "Apakah Anda Yakin ?",
        text: "Data yang terhapus tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteApi(props);
        }
      });
    },

    deleteApi(props) {
      return new Promise(() => {
        axios
          .delete("pendaftar/delete_sip?id_sip=" + props.row.id)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hapus Data Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Hapus Data SIP Berhasil`,
              },
            });
            this.getData();
          });
      });
    },

    mergeWaktuPraktek() {
      this.formSip.waktu_praktik =
        this.hari_praktik_sementara +
        " " +
        this.jam_praktik_sementara +
        "-" +
        this.jam_praktik_sementara_akhir;
    },

    showModalBerkas(props) {
      this.$router.push(
        "/pembuatan-sip/" +
          props.row.id_pendaftar +
          "/" +
          props.row.id +
          "/detail"
      );
    },

    showModalBerkasNull() {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: "File Dokumen Belum Ada Yang Terupload",
          icon: "XCircleIcon",
          variant: "danger",
        },
      });
    },

    handleSearch(searching) {
      this.getData(this.paging.size, this.paging.page);
    },
    handleChangePage(value) {
      const page = value - 1;
      this.getData(this.paging.size, page);
    },
    handlePageChange(active) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
    onSortChange(params) {
      this.sort = `${params[0].field}`;
      this.getData(this.paging.size, this.paging.page);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
